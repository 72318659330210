import { Menu } from 'primereact/menu';
import { useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import './Header.scss';

export default function Header() {
  const navigate = useNavigate();
  const user = useSelector((state: any) => state.auth.user);

  const accountMenuRef = useRef<any>(null);

  const accountMenuItems: any = [
    {
      label: 'ĐĂNG XUẤT',
      command: () => navigate('/logout'),
    },
  ];

  return (
    <div className="header fixed top-0 right-0 z-10 left-0 h-header flex items-center justify-between px-5 bg-white shadow">
      <div className="flex items-center">
        <Link to="/" className="h-header flex items-center">
          <img src="/logo_wobg.png" className="w-30 h-12" alt="" />
        </Link>
      </div>
      <h1 className="text-3xl hidden md:block">VIC CHAT</h1>
      <div className="flex items-center">
        <div className="flex items-center">
          <Menu
            model={accountMenuItems}
            popup
            ref={accountMenuRef}
            id="popup_menu_right"
            popupAlignment="right"
            // onClick={handleMenuClick}
          />
          <div className="cursor-pointer" onClick={(event) => accountMenuRef.current.toggle(event)}>
            <i className="pi pi-user border-2 rounded-full p-3 border-red-600"></i>
            <span className="underline underline-offset-2 ml-2">{user?.full_name || user?.email}</span>
          </div>
        </div>
      </div>
    </div>
  );
}
